.wd-short-title {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
  white-space: nowrap;

  @media screen and (max-width: 900px) {
    width: 100px;
  }
}
